@font-face {
  font-family: 'HyundaiSansHead';
  src: url(./fonts/HyundaiSansHead-Regular.woff) 
  format('woff');
}
@font-face {
  font-family: 'HyundaiSansHead';
  font-weight: 700;
  font-style: bold;
  src: url(./fonts/HyundaiSansHead-Medium.woff)
  format("woff");
}
@font-face {
  font-family: 'HyundaiSansHead';
  font-weight: 400;
  src: url(./fonts/HyundaiSansHead-Light.woff)
  format("woff");
}
@font-face {
  font-family: 'HyundaiSansText';
  src: url(./fonts/HyundaiSansText-Regular.woff) 
  format('woff');
}
@font-face {
  font-family: 'HyundaiSansText';
  font-weight: 700;
  font-style: bold;
  src: url(./fonts/HyundaiSansText-Bold.woff)
  format("woff");
}
@font-face {
  font-family: 'HyundaiSansText';
  font-weight: 400;
  src: url(./fonts/HyundaiSansText-Regular.woff)
  format("woff");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* disable scroll bar */
  -ms-overflow-style: none; /* IE */
  scrollbar-width: none; /* Firefox */
  overflow: -moz-scrollbars-none;
  
}

body::-webkit-scrollbar{
  display: none;
  width: 0 !important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

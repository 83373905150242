.grid-left {
    padding: 0 100px 0 0;

    @media (max-width: 1025px) {
        padding: 0;
    }
}

.grid-center {
    padding: 0 100px;

    border-right: 1px solid #DBDBDB;
    border-left: 1px solid #DBDBDB;

    @media (max-width: 1025px) {
        padding: 24px 0;
        border-right: none;
        border-left: none;

        border-top: 1px solid #DBDBDB;
        border-bottom: 1px solid #DBDBDB;
        margin: 24px 0;
    }
}

.grid-right {
    padding: 0 0 0 100px;


    @media (max-width: 1025px) {
        padding: 0 !important; 
    }
}

.grid-wrapper{
    @media (max-width: 1024px) {
        align-items: center;
        flex-direction: column !important;
    }

    @media (max-width: 899px) {
        flex-direction: row;
    }
}
@font-face {
  font-family: "HyundaiSansText-Bold";
  src: url("assets/fonts/HyundaiSansText-Bold.eot");
  src: url("assets/fonts/HyundaiSansText-Bold.eot?#iefix") format("embedded-opentype"),
  url("assets/fonts/HyundaiSansText-Bold.woff2") format("woff2"),
  url("assets/fonts/HyundaiSansText-Bold.woff") format("woff"),
  url("assets/fonts/HyundaiSansText-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HyundaiSansHead-Bold";
  src: url("assets/fonts/HyundaiSansHead-Bold.eot");
  src: url("assets/fonts/HyundaiSansHead-Bold.eot?#iefix") format("embedded-opentype"),
  url("assets/fonts/HyundaiSansHead-Bold.woff2") format("woff2"),
  url("assets/fonts/HyundaiSansHead-Bold.woff") format("woff"),
  url("assets/fonts/HyundaiSansHead-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GenesisSansText";
  src: url("assets/fonts/genesis/GenesisSansText-Regular.otf");

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GenesisSansText-Tooltip";
  src: url("assets/fonts/genesis/GenesisSansText-Medium.otf");

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GenesisSansHead";
  src: url("assets/fonts/genesis/GenesisSansHead-Light.otf");
  font-style: normal;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
}

.grid-root {
  display:none;
}

.tab-container .MuiBox-root .grid-root {
  justify-content: center;
}

.tab-container > .MuiBox-root {
  padding-left:0px;
  padding-right:0px;
}

.link-icon {
  position:relative;
  margin: 10px 0 0 !important;
  display: inline-block;
}

.link-icon:after {
  content:"";
  width:5px;
  height:8px;
  display:inline-block;
  margin-left:5px;
  background-size:auto 100%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.06728e-07 0.707013L4.122 4.82801L8.44282e-09 8.94901L0.707 9.65701L5.536 4.82801L0.707 1.24062e-05L1.06728e-07 0.707013Z' fill='%23002C5E'/%3E%3C/svg%3E%0A");
}

.select-root .MuiInput-root  {
  padding:7px 0px 7px 7px !important;
}

.tabs .Mui-selected {
  color:#333 !important;
  background-color:#ccc;
}

.endAdornment p {
  position: relative;
  left:-20px;
}

.cost-of-ownership-right  {
  margin-top:0px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #e6e6e6 !important;
  border-width: 1px !important;
}

.MuiTabs-centered .MuiTab-root {
  padding-left:0px !important;
  padding-right:0px !important;
  padding-bottom:0px !important;
  margin:0 10px;
  min-width: 60px !important;
  text-transform: none;
}

.MuiTabs-centered .MuiTab-root.Mui-selected {
  color:#333;
}

.MuiTabs-indicator {
  bottom: 10px !important;
  background-color:#00aad2 !important;
  height: 2px !important;
  padding: 0px 0px !important;
}

@media(max-width:360px) {
  .MuiTabs-centered .MuiTab-root {
    margin:0 5px;
    font-size:12px !important;
  }
}

.select-root .MuiInput-root select {
  position: relative;
    z-index: 9;
    padding-left:5px;
}

@media(max-width:768px) {
  .grid-left, .grid-center, .grid-right {
    max-width:375px !important;
  }
}

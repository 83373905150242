.headerDesk{
    @media(max-width: 1025px) {
        display: none;
    }
}

.zipcodeBox {
    width: 157px;

    @media(max-width: 1530px) {
       width: 90px;
    }
}

.msrpBox {
    width: 277px;

    @media(max-width: 1140px) {
       width: 250px;
    }
}

.annualMileageBox {
    width: 157px;

    @media(max-width: 1530px) {
        width: 150px;
     }
}

.mpgBox {
    width: auto;
    @media(max-width: 1530px) {
        width: 195px;
     }

     @media(max-width: 1300px) {
       width: 145px;
    }
}

.electricityBox {
    width: auto;
     @media(max-width: 1300px) {
       width: 195px;
    }
}

.fuelBox {
    width: auto;
    @media(max-width: 1530px) {
        width: 195px;
     }
     @media(max-width: 1300px) {
       width: 145px;
    }
}

.inputContainer {
    padding: 24px 18px;

    @media(max-width: 1530px) {
        padding: 20px 12px;
     }
}
.incentive-text-box {
    color: #141414;
    font-size: 16px;
    font-weight: 400;
    margin: 18px 0 55px 0 !important;
    
    @media(max-width: 1599px) {
        margin: 18px 0 24px 0 !important;
    }

    @media(max-width: 1024px) {
        margin: 18px 0 55px 0 !important;
    }
}
.headerMobile{
    width: 100%;
    height: auto;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media(min-width: 1025px) {
        display: none;
    }
}

.topSide{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media(min-width: 1025px) {
        display: none

    }
}

.lateralMenu {
    position: fixed;
    width: 97vw;
    height: 100vh;
    top: 0;
    right: 0;
    background: #F9F9F9;
    z-index: 1000;
    transform: translateX(-1025px);
    transition: all ease 0.3s;
    padding: 16px;
    overflow: hidden;

    @media(min-width: 1025px) {
        display: none
    }

    @media(max-width: 769px) {
        width: 96vw;
    }

    @media(max-width: 471px) {
        width: 93vw;
    }
}

.lateralMenu.open{
    transform: translateX(0px);

}

.header {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subHeader {
    margin-top: 36px;
}

.body {
    margin: 24px 0;
}

.footer {
    button{
        background: #141414;
        border: 1px solid #141414;
        color: #fff;
        width: 343px;
        height: 48px;
        font-weight: 400;
        font-size: 16px;
    }
}